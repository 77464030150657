import React from 'react';
import HeroBanner from '../../Components/Molecules/HeroBanner';


const Index: React.FC = props => {
      return (
        <>
            <HeroBanner/>
        </>
      );
    }

export default Index;